import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div>
        <h1>404 Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    );
  }
}

export default NotFound;
